import React from 'react';
import { Simple as Tooltip, TooltipContentProps } from '@/ui/Tooltip';
import * as Styled from './styled';

export type TagVariant =
  | 'white'
  | 'info'
  | 'error'
  | 'success'
  | 'warning'
  | 'default'
  | 'subtle'
  | 'transparent'
  | 'primary';

type TooltipProps = {
  content: string;
  side?: TooltipContentProps['side'];
  small?: boolean;
  style?: React.CSSProperties;
};

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  variant?: TagVariant;
  counter?: number;
  border?: boolean;
  pill?: boolean;
  small?: boolean;
  className?: string;
  tooltip?: TooltipProps;
}

const Tag: React.FC<TagProps> = ({
  children = '...',
  variant = 'default',
  counter,
  pill,
  small,
  className,
  border,
  tooltip,
  ...rest
}) => {
  const TagInstance = (
    <Styled.Tag className={className} $pill={pill} $small={small} $variant={variant} $withBorder={border} {...rest}>
      {children}
      {!!counter && <Styled.Counter>{counter}</Styled.Counter>}
    </Styled.Tag>
  );

  if (!tooltip) {
    return TagInstance;
  }

  return (
    <Tooltip content={tooltip.content} side={tooltip.side} small={tooltip.small} style={tooltip.style}>
      {TagInstance}
    </Tooltip>
  );
};

export default Tag;
